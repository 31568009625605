var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual-intro'), _c('section', {
    staticClass: "section grey-fa"
  }, [_c('v-container', [_c('tab-intro'), _c('v-card', {
    staticClass: "grey-b3 mb-40 mb-lg-60",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "w-100 white pa-30 pa-md-40 py-lg-80"
  }, [_c('div', {
    staticClass: "mb-40 mb-lg-60 text-center"
  }, [_c('h3', {
    staticClass: "tit line-height-1 mb-8 mb-lg-16"
  }, [_vm._v(" SINCE "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("1989")])]), _c('p', {
    staticClass: "page-text page-text--lg line-height-1"
  }, [_vm._v(" 1989년 위드캔복지재단의 시작 ")])]), _c('v-img', {
    staticClass: "d-none d-md-block w-100 mx-auto",
    attrs: {
      "src": "/images/sub/company/intro/graph.svg",
      "max-width": "1012",
      "contain": ""
    }
  }), _c('v-img', {
    staticClass: "d-block d-md-none w-100 mx-auto",
    attrs: {
      "src": "/images/sub/company/intro/graph-mo.svg",
      "max-width": "672",
      "contain": ""
    }
  })], 1)]), _c('v-row', {
    staticClass: "row-cols-md-5 row-cols-lg-auto",
    attrs: {
      "justify": "center",
      "justify-lg": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "auto"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('div', {
    staticClass: "icon-wrap mb-16 mb-lg-28"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/company/intro/s2-icon1.svg",
      "max-height": "56",
      "contain": ""
    }
  })], 1), _c('h6', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8"
  }, [_vm._v(" 전문성 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" Professionalism ")])])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "auto"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('div', {
    staticClass: "icon-wrap mb-16 mb-lg-28"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/company/intro/s2-icon2.svg",
      "max-height": "56",
      "contain": ""
    }
  })], 1), _c('h6', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8"
  }, [_vm._v(" 인간존중 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" Respect for human being ")])])]), _c('v-col', {
    staticClass: "mt-10 mt-sm-0",
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "auto"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('div', {
    staticClass: "icon-wrap mb-16 mb-lg-28"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/company/intro/s2-icon3.svg",
      "max-height": "56",
      "contain": ""
    }
  })], 1), _c('h6', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8"
  }, [_vm._v(" 변화와 도전 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" Change and challenge ")])])]), _c('v-col', {
    staticClass: "mt-10 mt-md-0",
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "auto"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('div', {
    staticClass: "icon-wrap mb-16 mb-lg-28"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/company/intro/s2-icon4.svg",
      "max-height": "56",
      "contain": ""
    }
  })], 1), _c('h6', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8"
  }, [_vm._v(" 투명성 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" Transparency ")])])]), _c('v-col', {
    staticClass: "mt-10 mt-md-0",
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "auto"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('div', {
    staticClass: "icon-wrap mb-16 mb-lg-28"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/company/intro/s2-icon5.svg",
      "max-height": "56",
      "contain": ""
    }
  })], 1), _c('h6', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8"
  }, [_vm._v(" 사회연대 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" Social solidarity ")])])])], 1)], 1)], 1), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('v-card', {
    staticClass: "grey-b3 image-card mb-40 mb-lg-60",
    staticStyle: {
      "background-image": "url(/images/sub/company/intro/s1-bg.jpg)"
    },
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "w-100 pa-30 pa-md-40 py-lg-80"
  }, [_c('v-img', {
    staticClass: "w-100 mh-100px mh-lg-200px mx-auto",
    attrs: {
      "src": "/images/ci-vertical.svg",
      "contain": ""
    }
  })], 1)]), _c('v-row', {
    staticClass: "row--large"
  }, [_c('v-col', {
    staticClass: "mb-12 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm line-height-1 primary--text"
  }, [_vm._v(" WITH-"), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v("CAN")])]), _c('v-divider', {
    staticClass: "my-8 my-lg-16"
  }), _c('div', {
    staticClass: "line-height-1"
  }, [_c('div', {
    staticClass: "d-flex align-center mb-8 mb-lg-16"
  }, [_c('h4', {
    staticClass: "tit tit--sm secondary--text line-height-1 w-auto"
  }, [_vm._v(" C ")]), _c('p', {
    staticClass: "font-size-14 font-size-lg-18 grey-6--text"
  }, [_vm._v(" hange & Challenge (도전과 변화) ")])]), _c('div', {
    staticClass: "d-flex align-center mb-8 mb-lg-16"
  }, [_c('h4', {
    staticClass: "tit tit--sm secondary--text line-height-1 w-auto"
  }, [_vm._v(" A ")]), _c('p', {
    staticClass: "font-size-14 font-size-lg-18 grey-6--text"
  }, [_vm._v(" ccountability (전문사회복지법인으로써의 책무성) ")])]), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('h4', {
    staticClass: "tit tit--sm secondary--text line-height-1 w-auto"
  }, [_vm._v(" N ")]), _c('p', {
    staticClass: "font-size-14 font-size-lg-18 grey-6--text"
  }, [_vm._v(" ew Normal (사회복지 환경 변화에 따른 새로운 지평) ")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm line-height-1"
  }, [_vm._v(" 의미 ")]), _c('v-divider', {
    staticClass: "my-8 my-lg-16"
  }), _c('p', {
    staticClass: "page-text word-keep-all"
  }, [_vm._v(" 사랑과 나눔, 협력의 가치 구현으로 도전과 변화, 전문사회복지법인으로써의 책무성을 수행하며, 또한 사회복지 환경 변화에 따른 새로운 지평을 열어 모두가 함께 행복한 세상을 만들어가자는 의미. ")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }