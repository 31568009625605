<template>
    <client-page>

        <sub-visual-intro />

        <section class="section grey-fa">
            <v-container>

                <tab-intro />

                <v-card outlined class="grey-b3 mb-40 mb-lg-60">
                    <div class="w-100 white pa-30 pa-md-40 py-lg-80">
                        <div class="mb-40 mb-lg-60 text-center">
                            <h3 class="tit line-height-1 mb-8 mb-lg-16">
                                SINCE <span class="primary--text">1989</span>
                            </h3>
                            <p class="page-text page-text--lg line-height-1">
                                1989년 위드캔복지재단의 시작
                            </p>
                        </div>
                        <v-img src="/images/sub/company/intro/graph.svg" max-width="1012" contain class="d-none d-md-block w-100 mx-auto"></v-img>
                        <v-img src="/images/sub/company/intro/graph-mo.svg" max-width="672" contain class="d-block d-md-none w-100 mx-auto"></v-img>
                    </div>
                </v-card>

                <v-row justify="center" justify-lg="space-between" class="row-cols-md-5 row-cols-lg-auto">
                    <v-col cols="6" sm="4" lg="auto">
                        <div class="icon-item">
                            <div class="icon-wrap mb-16 mb-lg-28">
                                <v-img src="/images/sub/company/intro/s2-icon1.svg" max-height="56" contain></v-img>
                            </div>
                            <h6 class="page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8">
                                전문성
                            </h6>
                            <p class="page-text">
                                Professionalism
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="4" lg="auto">
                        <div class="icon-item">
                            <div class="icon-wrap mb-16 mb-lg-28">
                                <v-img src="/images/sub/company/intro/s2-icon2.svg" max-height="56" contain></v-img>
                            </div>
                            <h6 class="page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8">
                                인간존중
                            </h6>
                            <p class="page-text">
                                Respect for human being
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="4" lg="auto" class="mt-10 mt-sm-0">
                        <div class="icon-item">
                            <div class="icon-wrap mb-16 mb-lg-28">
                                <v-img src="/images/sub/company/intro/s2-icon3.svg" max-height="56" contain></v-img>
                            </div>
                            <h6 class="page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8">
                                변화와 도전
                            </h6>
                            <p class="page-text">
                                Change and challenge
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="4" lg="auto" class="mt-10 mt-md-0">
                        <div class="icon-item">
                            <div class="icon-wrap mb-16 mb-lg-28">
                                <v-img src="/images/sub/company/intro/s2-icon4.svg" max-height="56" contain></v-img>
                            </div>
                            <h6 class="page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8">
                                투명성
                            </h6>
                            <p class="page-text">
                                Transparency
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="4" lg="auto" class="mt-10 mt-md-0">
                        <div class="icon-item">
                            <div class="icon-wrap mb-16 mb-lg-28">
                                <v-img src="/images/sub/company/intro/s2-icon5.svg" max-height="56" contain></v-img>
                            </div>
                            <h6 class="page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8">
                                사회연대
                            </h6>
                            <p class="page-text">
                                Social solidarity
                            </p>
                        </div>
                    </v-col>
                </v-row>

            </v-container>
        </section>

        <section class="section">
            <v-container>



                <v-card outlined class="grey-b3 image-card mb-40 mb-lg-60" style="background-image: url(/images/sub/company/intro/s1-bg.jpg)">
                    <div class="w-100 pa-30 pa-md-40 py-lg-80">
                        <v-img src="/images/ci-vertical.svg" contain class="w-100 mh-100px mh-lg-200px mx-auto"></v-img>
                    </div>
                </v-card>

                <v-row class="row--large">
                    <v-col cols="12" md="6" class="mb-12 mb-md-0">
                        <h4 class="tit tit--sm line-height-1 primary--text">
                            WITH-<span class="secondary--text">CAN</span>
                        </h4>
                        <v-divider class="my-8 my-lg-16"></v-divider>
                        <div class="line-height-1">
                            <div class="d-flex align-center mb-8 mb-lg-16">                            
                                <h4 class="tit tit--sm secondary--text line-height-1 w-auto">
                                    C
                                </h4>
                                <p class="font-size-14 font-size-lg-18 grey-6--text">
                                    hange & Challenge (도전과 변화)
                                </p>
                            </div>
                            <div class="d-flex align-center mb-8 mb-lg-16">                            
                                <h4 class="tit tit--sm secondary--text line-height-1 w-auto">
                                    A
                                </h4>
                                <p class="font-size-14 font-size-lg-18 grey-6--text">
                                    ccountability (전문사회복지법인으로써의 책무성)
                                </p>
                            </div>
                            <div class="d-flex align-center ">                            
                                <h4 class="tit tit--sm secondary--text line-height-1 w-auto">
                                    N
                                </h4>
                                <p class="font-size-14 font-size-lg-18 grey-6--text">
                                    ew Normal (사회복지 환경 변화에 따른 새로운 지평)
                                </p>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <h4 class="tit tit--sm line-height-1">
                            의미
                        </h4>
                        <v-divider class="my-8 my-lg-16"></v-divider>
                        <p class="page-text word-keep-all">
                            사랑과 나눔, 협력의 가치 구현으로 도전과 변화, 전문사회복지법인으로써의 책무성을 수행하며, 또한 사회복지 환경 변화에 따른 새로운 지평을 열어 모두가 함께 행복한 세상을 만들어가자는 의미.
                        </p>
                    </v-col>
                </v-row>

            </v-container>
        </section>



    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisualIntro from "@/components/client/sub/sub-visual-intro.vue";
import TabIntro from "@/components/client/sub/tab-intro.vue";
export default {
    components: {
        ClientPage,
        SubVisualIntro,
        TabIntro,
    },
};
</script>

<style lang="scss" scoped>
.icon-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.icon-wrap{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 1px solid var(--v-primary-base);
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .icon-wrap{
        width: 160px;
        height: 160px;
    }
}
@media (min-width:1200px){
}

</style>